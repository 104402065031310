const layoutAnalysis = {
  // 重置上报参数
  refreshAnalysisParams ({ params = {} } = {}) {
    if(typeof window != 'undefined') {
      // window.PageGroupOverview = 'home'
      // window.PageGroup = 'home'
      // 页面信息
      window.SaPageInfo = {
        page_id: 1,
        page_name: 'page_home',
        page_param: Object.assign({}, params),
        start_time: new Date().getTime()
      }
      // this.exposeGoodIdList = []
    }
  },
}
export default layoutAnalysis
